import { h } from 'preact';
import { useState } from 'preact/hooks';
import Divider from '../divider/divider';
import style from './style.css';

interface IProps {
    children: JSX.Element | JSX.Element[];
    elementId?: string;
    autoStyle?: boolean;
    dividerTop?: boolean;
    dividerBottom?: boolean;
    bgImg?: string;
    bgColor?: string;
    isStrokedDividerTop?: boolean;
    isStrokedDividerBottom?: boolean;
}

export default function ContentWrapper (props: IProps) {

    // const [baseUrl] = useState(window.location.hostname);

    return (
        <section class={style.wrapper} id={props.elementId}
            style={
                { 
                    backgroundImage: `url('${props.bgImg ? props.bgImg : '/assets/img/bg.jpg'}')`,
                    backgroundColor: props.bgColor ? props.bgColor : 'rgba(8,8,8,0.88)'
                }
            }
        >
            {props.dividerTop && <Divider isTop isParallel isStroked={props.isStrokedDividerTop} />}
            {props.autoStyle ? <section class={style.content}>{props.children}</section> : props.children}
            {props.dividerBottom && <Divider isTop={false} isStroked={props.isStrokedDividerBottom} />}
        </section>
    )
}