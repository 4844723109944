import { h } from "preact";
import { useState } from "preact/hooks";
import style from './style.css';

interface IProps {
    isTop: boolean;
    isParallel?: boolean;
    isStroked?: boolean;
}

export default function Divider(props: IProps) {
    const { isTop, isParallel, isStroked }: IProps = props;

    const [assetName] = useState(isStroked ? 'divider_stroked.svg' : 'divider.svg');

    return (
        <div class={style.divider}
            style={isTop ? { top: 0, bottom: 'initial', transform: `rotateX(180deg) scale(1.1) ${isParallel ? 'rotateY(180deg)' : ''}` } : undefined}
        >
            {/* <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class={style.shape_fill}></path>
            </svg> */}

            <img alt='divider' src={`./assets/svg/${assetName}`} />
            {/* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1200 126.82">

                <path class={style.cls1}
                    d="M985.66,91.22c-79-20.83-161.88-61.83-241.82-78.64C661.58-4.76,575.78-3.75,493.39,13c-57.84,11.73-114,31.07-172,41.86A600.1,600.1,0,0,1,0,25.74v92.65H1200V94.19C1132.19,117.31,1055.71,109.7,985.66,91.22Z" />
                <path class={style.cls2}
                    d="M985.66,99.65C906.67,78.82,823.78,37.82,743.84,21,661.58,3.67,575.78,4.68,493.39,21.4c-57.84,11.73-114,31.07-172,41.86A600.3,600.3,0,0,1,0,34.17v92.65H1200v-24.2C1132.19,125.74,1055.71,118.13,985.66,99.65Z" />
            </svg > */}
        </div>
    );
}