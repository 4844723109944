import { h } from "preact";
import style from './style.css';

export default function LoadSpinner() {
    return (
        <div class={style.spinning}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.06 134.53">
                <g id="Réteg_2" data-name="Réteg 2">
                    <g id="Objects">
                        <path class={style.svg_path}
                            d="M101.41,41.31c1.53-6.52-.7-12.58-4.48-17.88C91.54,15.86,84.84,9,78.8,2,78,1,75.36-1,74.21.64c-7.44,10.77-16,22.63-18.09,35.89-1.91,12,6,21.79,13.39,30.21a3.42,3.42,0,0,0,2.31,1.19,2.65,2.65,0,0,0,1.08.24C86.09,67.48,98.5,53.66,101.41,41.31ZM71.66,63.49C64.35,55,59,46.15,62.5,34.46c3-10.29,9-19.37,15.09-28.15,3.88,4.53,7.78,9.06,11.63,13.62,4.64,5.5,8.66,11.28,6.9,18.85C93.61,49.56,83,61.67,71.66,63.49Z" />
                        <path class={style.svg_path}
                            d="M56.52,73.31c2-.68,5.85-1.94,7.29-3.44,1.78-1.85.28-3.57-.92-5.3-4-5.59-8.4-11-12.79-16.31-3.41-4.15-7.27-7.85-12.44-9.64-12-4.16-26.12,2.21-37.1,6.83-1,.41-.49,1.88,0,2.46,8.64,10.89,18.56,25.8,33.25,28.3C41.49,77.52,49.23,75.8,56.52,73.31ZM15.77,60.11c-3.29-3.63-6.44-7.38-9.51-11.19,8.86-3.77,20.65-9,30.13-6.57,5.73,1.46,8.93,6.06,12.42,10.47,3.31,4.16,6.7,8.25,9.82,12.56,1.14,1.64-11,5.18-12.28,5.5-5.86,1.44-12.37,2.06-18.1-.26C23.08,68.53,19.43,64.16,15.77,60.11Z" />
                        <path class={style.svg_path}
                            d="M144.84,53.17c-6.37,1.16-12.49-2.05-18.73-3a38.76,38.76,0,0,0-15.36.59c-10.91,2.87-16.82,12.14-25.86,17.9-1.13.72-.58,2.06.51,3.07a5,5,0,0,0,.51.57c.31.3.63.59,1,.88,1.18,1.11,2.37,2.18,3.64,3.2a4.7,4.7,0,0,0,.41.3c8.44,6.74,17.86,10.85,29.3,8.15,13.27-3.14,23.57-15.49,28.69-27.73C149.77,55,146.77,52.82,144.84,53.17Zm-9.33,14.15c-3.54,4.22-7.55,9-12.67,11.29-5.44,2.41-12.49,3.6-18.24,2-4.51-1.26-8.41-4-11.95-7.08l-.08-.07-.23-.2c-.57-.5-1.14-1-1.7-1.53,8.31-5.9,14.26-14.67,24.9-16.86a37.71,37.71,0,0,1,16.8.74A74.8,74.8,0,0,0,142,57.78,54.71,54.71,0,0,1,135.51,67.32Z" />
                        <path class={style.svg_path}
                            d="M96.43,84.5c-6.22-2.37-12.49-4.6-18.73-6.9-.76-.28-2.15-.14-2.29.88-1,7.14-1.11,14.38-2,21.55-.71,5.72-.53,11.68,2.81,16.61,2.85,4.22,7.26,7,11.8,9,6.58,3.05,13.4,5.67,20.1,8.47.84.36,2.77.82,3-.61.86-5.84,1.62-11.69,2.24-17.56.53-5.1,1.24-10.46-.12-15.5C111,92.46,103.75,87.46,96.43,84.5Zm12.19,22.66c-.38,7.38-1.47,14.79-2.55,22.1l-12.43-5.2c-4.78-2-10.65-3.91-13.37-8.66-3-5.17-1.84-11.37-.91-17,.87-5.24.62-10.53,1.15-15.79,5.08,1.86,10.18,3.68,15.23,5.61C104.48,91.79,109.13,97.4,108.62,107.16Z" />
                        <path class={style.svg_path}
                            d="M65.05,75.22c-12.71,2.52-25.3,2.2-35.14,11.89-4,3.94-7.75,8.61-9,14.18-1.59,6.81-2.05,14-3,20.95-.24,1.76,2.05,3.5,3.66,3.52,6.49.1,13,0,19.47-.2,5.27-.21,10.95,0,16-1.85,8.69-3.12,12.45-12.26,13.36-20.82.53-8.27-.92-16.44-1-24.7C69.27,76.47,66.57,74.92,65.05,75.22Zm0,25.14c-.9,8.55-4.63,17.43-13.78,19.61-8.65,2.07-18.52,1.62-27.64,1.37.66-5.06,1.28-10.13,2.11-15.16,1-5.78,3.91-10.21,7.89-14.39,8.53-8.94,19.33-9.57,30.54-11.38C64.52,87.06,65.46,93.68,65,100.36Z" />
                    </g>
                </g>
            </svg>
        </div>
    )
}