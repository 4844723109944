import { useEffect, useCallback, useState, useMemo } from 'preact/hooks';

export function useGalleryHandler(props: any) {
    const { galleryObj, setGalleryObj, chunkCount, images, chunkedImages, setSnapToMiddle, setTransformX, imageWidth } = props;

    const [currentChunkIdx, setCurrentChunkIdx] = useState(-1);
    const [lastScrollPos, setLastScrollPos] = useState(0);
    const currentImgIdx = useMemo(() => galleryObj.currentImgIdx, [galleryObj]);

    const closeGallery = useCallback(() => {
        document.body.style.position = 'initial';
        document.body.style.top = '';

        document.documentElement.style.scrollBehavior = 'initial';
        window.scrollTo(0, lastScrollPos)
        document.documentElement.style.scrollBehavior = 'smooth';

        setGalleryObj({
            isOpen: false
        });
    }, [lastScrollPos, setGalleryObj]);

    function stepLeft() {
        let newImgIdx = currentImgIdx;
        let hadReset = false;

        if (currentImgIdx - 1 < 0) {
            newImgIdx = images?.length - 1;
            hadReset = true;
        } else {
            newImgIdx = currentImgIdx - 1;
        }

        if (!hadReset) {
            if ((((currentChunkIdx) * chunkCount) % currentImgIdx === 0) && currentChunkIdx > 0) {
                setCurrentChunkIdx(p => p - 1);
            }
        } else {
            setCurrentChunkIdx(chunkedImages?.length - 1);
        }

        return newImgIdx;
    }

    function stepRight() {
        let newImgIdx = currentImgIdx;

        if (currentImgIdx + 1 > images.length - 1) {
            newImgIdx = 0;
            setCurrentChunkIdx(0);
        } else {
            newImgIdx = currentImgIdx + 1;
        }

        if ((newImgIdx % chunkCount === 0) && currentChunkIdx <= chunkedImages.length && newImgIdx > 0) {
            setCurrentChunkIdx(p => p + 1);
        }

        return newImgIdx;
    }

    const handleKeyPress = (e: KeyboardEvent) => {
        let newImgIdx = currentImgIdx;

        if (e.keyCode === 27) {
            closeGallery();
            return;
        }

        if (e.keyCode === 39) { //right
            newImgIdx = stepRight();
        }

        if (e.keyCode === 37) { //left
            newImgIdx = stepLeft();
        }

        if (newImgIdx !== currentImgIdx) {
            
            setGalleryObj({
                ...galleryObj,
                currentImgIdx: newImgIdx
            });
        }
    };

    const handleClick = (e: any) => {
        if (e.target.nodeName === 'DIV') {
            closeGallery();
        }
    };

    const handleScrollChange = (e?: Event) => {
        if (!galleryObj.isOpen)
            setLastScrollPos(window.scrollY)
    }

    useEffect(() => {
        if (!galleryObj.isOpen) {
            return;
        }

        setCurrentChunkIdx(Math.floor((currentImgIdx) / chunkCount));
    }, [galleryObj.isOpen]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener('scroll', handleScrollChange);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener('scroll', handleScrollChange);
        };
    });

    return {
        currentImgIdx,
        stepLeft,
        stepRight,
        lastScrollPos,
        handleClick,
        currentChunkIdx,
        closeGallery
    }
}