import { h } from 'preact';
import Divider from '../divider/divider';
import style from './style.css';
import ContentWrapper from '../contentWrapper/contentWrapper';

export default function Footer() {
    return (
        <ContentWrapper dividerTop elementId='elerhetosegek' bgImg='https://vass-art-and-beauty.imgix.net/bg_footer.JPG?q=65&amp;w=2000' bgColor='rgba(8,8,8,.7)'>
            <section class={style.content}>

                <section class={style.contact_content}>
                    <div class={style.div_contact}>
                        <h2>Elérhetőségek</h2>

                        <div class={style.contacts}>
                            <div class={style.contact_row}>
                                <div class={style.contact_block}>
                                    <div class={style.contact_img}>
                                        <img src='/assets/svg/phone.svg' alt='phone icon' />
                                    </div>
                                    <a href='tel:+36205875115'>+36 20 587 5115</a>
                                </div>
                                <div class={style.contact_block}>
                                    <div class={style.contact_img}>
                                        <img src='/assets/svg/mail.svg' alt='mail icon' />
                                    </div>
                                    <a href='mailto:info@vassvirag.hu'>info@vassvirag.hu</a>
                                </div>
                            </div>
                            <div class={style.contact_row}>
                                <div class={style.contact_block}>
                                    <div class={style.contact_img}>
                                        <img src='/assets/svg/fb.svg' alt='facebook icon' />
                                    </div>
                                    <a href='https://facebook.com/vassartandbeauty'>vassartandbeauty</a>
                                </div>

                                <div class={style.contact_block}>
                                    <div class={style.contact_img}>
                                        <img src='/assets/svg/ig.svg' alt='instagram icon' />
                                    </div>
                                    <a href='https://instagram.com/vass_art_and_beauty'>@vass_art_and_beauty</a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class={style.map}>
                        <iframe title='map' onLoad={ev => {
                            if (ev.currentTarget.contentDocument)
                                ev.currentTarget.contentDocument.body.style.background = 'red';
                        }} frameBorder={0} src="https://maps.google.com/maps?q=9022%20Gy%C5%91r%20Czuczor%20Gergely%20utca%2018-24&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
                    </div>

                    <footer>Vass Virág © 2023</footer>
                </section>


                <picture class={style.div_portre}>
                    <source srcset="https://vass-art-and-beauty.imgix.net/portre.JPG?w=1000&amp;q=70&amp;fm=webp" type="image/webp" />
                    <img alt="Vass Virág" src="https://vass-art-and-beauty.imgix.net/portre.JPG?w=1000&amp;q=70" />
                </picture>
            </section>
        </ContentWrapper>
    );
}