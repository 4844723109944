import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import LoadSpinner from '../loadSpinner/loadSpinner';
import style from './style.css';

export default function Loading(props: { isResourcesLoaded: boolean }) {
    const { isResourcesLoaded } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const handleIsLoaded = (e?: Event) => {
        if (!isLoaded) {
            document.documentElement.style.position = 'initial';
            document.body.style.position = 'initial';

            setTimeout(() => {
                window.scrollTo(0,0);

                setTimeout(() => {
                    setIsLoaded(true);
                }, 100);
            }, 100);
            // setTimeout(() => {
            //     setIsHidden(true)
            // }, 500);
        }
    };

    useEffect(() => {

        if (!isResourcesLoaded) {
            return;
        }

        if (typeof window !== 'undefined') {
            handleIsLoaded();
            window.addEventListener('load', handleIsLoaded);

            return () => {
                window.removeEventListener('load', handleIsLoaded);
                // handleIsLoaded();
            };
        }

    }, [isResourcesLoaded])

    // if (isHidden) {
    //     return null;
    // }


    return (
        <div class={style.loadingWrapper} style={{
            opacity: !isLoaded ? 1 : 0,
        }}>
            <LoadSpinner />
        </div>
    );
}