import { h } from 'preact';
import { chunk, delay, isEmpty } from 'lodash';
import { useContext, useEffect, useMemo, useState, useRef, useCallback } from 'preact/hooks';
import { mainContext } from '../context/MainContext';
import LoadSpinner from '../loadSpinner/loadSpinner';
import { IImage } from './gallery/gallery';
import style from './style.css';
import { useGalleryHandler } from './useGalleryHandler';
import { useMutationObservable } from '../../hooks/useMutationObserver';
import { preloadImage } from '../dataWrapper/useOnLoadImages';
import { Blurhash } from 'react-blurhash';

interface IProps {
    url: string;
    label?: string;
}

const DIRECTIONS = {
    LEFT: 1,
    RIGHT: 2
}

function ImagePlaceholder(props: any) {
    const { blurhash } = props;

    const wrapperRef = useRef<HTMLImageElement | null>(null);
    const [imgSize, setImgSize] = useState<any>();

    useEffect(() => {
        if (!wrapperRef.current) {
            return;
        }

        setImgSize({
            width: wrapperRef.current.offsetWidth,
            height: wrapperRef.current.offsetHeight,
        })
    }, [wrapperRef.current])

    return (
        <div class={style.img_wrapper} >
            <img ref={wrapperRef} style={{
                display: 'block',
                objectFit: 'cover',
                width: 'auto',
                maxHeight: '75vh'
            }} src="./assets/uploads/loading.png" alt="Betöltés" />

            {
                blurhash && imgSize ?
                    (<div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}>
                        <Blurhash
                            hash={blurhash}
                            width={imgSize?.width}
                            height={imgSize?.height}
                            resolutionX={32}
                            resolutionY={32}
                            punch={1}
                        />
                    </div>) : null
            }
        </div>
    )
}


export default function ImageOverlay() {
    // const [isOpen, setIsOpen] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(true);

    const [isTouchInProgress, setIsTouchInProgress] = useState<boolean>(false);

    const [snapToMiddle, setSnapToMiddle] = useState(false);

    const [isDoubleTouch, setIsDoubleTouch] = useState(false);

    const [isPrevLoaded, setIsPrevLoaded] = useState(false);
    const [isNextLoaded, setIsNextLoaded] = useState(false);

    const [newTouchIdx, setNewTouchIdx] = useState<number | null>(null);
    const [imageWidth, setImageWidth] = useState(0);
    const [transformX, setTransformX] = useState(0);
    const [touchStart, setTouchStart] = useState({ x: 0, y: 0 });
    const [testDeltaX, setTestDeltaX] = useState(0);

    const [preloadedImg, setPreloadedImg] = useState<string[]>();

    const modalRef = useRef<HTMLDivElement | null>(null);

    const imgRef = useRef<HTMLImageElement | null>(null);

    const [transition, setTransition] = useState('transform 0.2s ease, opacity 0.2s ease');

    const { galleryObj, setGalleryObj, isWebPSupported }: any = useContext(mainContext);

    const [touchTimeStart, setTouchTimeStart] = useState(0);
    const [touchTimeEnd, setTouchTimeEnd] = useState(0);

    const [isLocked, setIsLocked] = useState(false);

    const chunkCount = useMemo(() => galleryObj.chunkCount, [galleryObj]);
    const chunkedImages = useMemo(() => chunk(galleryObj.images?.map((img: any, idx: number) => ({ ...img, idx })), chunkCount), [galleryObj]);

    const images = useMemo(() => galleryObj?.images, [galleryObj]);

    const prevImgIndex = useMemo(() => {
        const currentImgIdx = galleryObj?.currentImgIdx;

        if (currentImgIdx - 1 < 0) {
            return galleryObj?.images?.length - 1;
        }

        return galleryObj?.currentImgIdx - 1
    }, [galleryObj]);

    const nextImgIndex = useMemo(() => {
        const currentImgIdx = galleryObj?.currentImgIdx;

        if (currentImgIdx + 1 >= galleryObj?.images?.length) {
            return 0;
        }

        return galleryObj?.currentImgIdx + 1
    }, [galleryObj]);

    useEffect(() => {
        setIsNextLoaded(true);
    }, [nextImgIndex]);

    useEffect(() => {
        setIsPrevLoaded(true);
    }, [prevImgIndex]);


    const { currentImgIdx, stepLeft, stepRight, lastScrollPos, handleClick, currentChunkIdx, closeGallery } = useGalleryHandler({
        galleryObj,
        setGalleryObj,
        images,
        chunkCount,
        chunkedImages,
        setSnapToMiddle,
        setTransformX,
        imageWidth
    });

    useEffect(() => {
        if (!galleryObj?.isOpen) return;

        const curImg = galleryObj?.images?.[currentImgIdx]?.original;
        const prevImg = galleryObj?.images?.[prevImgIndex]?.original;
        const nextImg = galleryObj?.images?.[nextImgIndex]?.original;

        async function preloadGalleryImages(images: string[]) {
            await Promise.all(images.map(imgSrc => preloadImage(imgSrc, isWebPSupported)));

            setIsLoading(false);
        }

        const nonLoadedImages = [curImg, prevImg, nextImg];

        if (!isEmpty(nonLoadedImages)) {
            preloadGalleryImages(nonLoadedImages);
        } else {
            setIsLoading(false);
        }

    }, [currentImgIdx, prevImgIndex, nextImgIndex, galleryObj]);

    useEffect(() => {
        if (galleryObj.isOpen) {

            setTransformX(0);

            // if (lastScrollPos > 0) {
            document.body.style.top = `-${lastScrollPos}px`;
            document.body.style.position = 'fixed';
            // document.body.style.overflowY = 'hidden';
            // }
        }
    }, [galleryObj]);

    useEffect(() => {
        if (!imgRef?.current || isLoading) {
            return;
        }

        setImageWidth(imgRef.current.clientWidth);
    }, [imgRef, isLoading]);


    if (!galleryObj.isOpen) {
        return null
    }

    function onImgClick(e: MouseEvent, idx: number) {
        e.stopPropagation();
        e.preventDefault();

        setGalleryObj({
            ...galleryObj,
            currentImgIdx: idx
        });
    }

    function getCurrentImageStyle(idx: number) {
        const currentStyle = {
            // width: `${footerImgWidth}px`,
            transform: 'scale(1)',
            border: 'none'
        };

        if (idx === galleryObj.currentImgIdx) {
            currentStyle.transform = 'scale(.95)';
            currentStyle.border = '2px solid white';
        }

        return currentStyle;
    }

    function handleTouchMove(e: TouchEvent) {


        if (isDoubleTouch) {
            return;
        }

        if (!isTouchInProgress || isLocked) {
            return;
        }

        const touchX = e.touches[0].screenX;

        setTransformX((touchStart.x - touchX) * 1.15);
        // setTestDeltaX(((touchStartX - touchX) / imageWidth))
    }

    const handletouchStart = (e: TouchEvent) => {
        if (isLocked || !isNextLoaded || !isPrevLoaded) return;

        if (e.touches.length > 1 || e.changedTouches.length > 1) {
            setTransformX(0);
            setIsDoubleTouch(true);
            return;
        }

        if (imgRef.current) {
            imgRef.current.style.transition = '';
        }

        setIsTouchInProgress(true);
        setSnapToMiddle(true);
        setIsLocked(false)

        let date = new Date();
        setTouchTimeStart(date.getTime());

        setTouchStart({
            x: e.changedTouches?.[0]?.screenX,
            y: e.changedTouches?.[0]?.screenY
        });
    }

    const handletouchEnd = (e: TouchEvent) => {
        const changedTouches = e.changedTouches[0];

        if (isDoubleTouch) {
            setTimeout(() => {
                setIsDoubleTouch(false);
            }, 150);
            return;
        }

        // const elemEnd = document.elementFromPoint(changedTouches.clientX, changedTouches.clientY);

        // if (elemEnd && elemEnd.nodeName === 'DIV') {
        //     closeGallery();
        //     return;
        // }

        let newImgIdx = currentImgIdx;
        const touchEndX = changedTouches.screenX;
        const touchEndY = changedTouches.screenY;

        const deltaX = ((touchStart.x - touchEndX));
        const deltaY = ((touchStart.y - touchEndY) / imageWidth);

        // setTestDeltaX(deltaX);

        let date = new Date();

        const canSwitchImage = Math.abs(transformX) > Math.abs(imageWidth / 2.5);
        const isFastSwitch = (date.getTime() - touchTimeStart) < 120;

        if (imgRef.current) {
            imgRef.current.style.transition = transition;
        }

        newImgIdx = deltaX > 0 ? stepRight() : stepLeft();

        if ((!canSwitchImage && !isFastSwitch) || Math.abs(deltaY) > .1) {
            setTransformX(0);
            return;
        }

        if (touchEndX < touchStart.x) {
            setTransformX(imageWidth);
        } else {
            setTransformX(imageWidth * -1);
        }

        if (newImgIdx !== currentImgIdx) {
            setNewTouchIdx(newImgIdx);

            // setTimeout(() => {
            if (imgRef.current) imgRef.current.style.opacity = '0';
            setTimeout(() => {
                if (imgRef.current) imgRef.current.style.opacity = '1';
                setGalleryObj({
                    ...galleryObj,
                    currentImgIdx: newImgIdx
                });
            }, 175);
            // setSnapToMiddle(false);
            // }, 400);

            setIsTouchInProgress(false);
        }

    }

    return (
        <div class={style.overlay} onClick={handleClick} ref={modalRef}>
            {/* <section class={style.overlay_img}> */}

            <picture class={style.img_wrapper}>
                {/* <img class={style.next_image} src={galleryObj?.images[prevImgIndex]?.original}
                    onLoad={() => setIsPrevLoaded(true)}
                    style={{
                        pointerEvents: 'none',
                        // opacity: transformX < 0 ? ((Math.abs(transformX)) / Math.abs(imageWidth)) : 0,

                        transform: `translateX(calc(-100% - ${transformX}px))`,
                        // transition: snapToMiddle ? transition : '',
                        // display :isNextLoaded && isPrevLoaded && !isLoading ? 'block' : 'none',

                    }}
                /> */}
                {isWebPSupported && !isLoading ?
                    <source srcset={`${galleryObj.images[galleryObj.currentImgIdx].original}&fm=webp`} type="image/webp" />
                    : null}

                {!isLoading ? <img
                    src={galleryObj.images[galleryObj.currentImgIdx].original}
                    alt={galleryObj.label}
                    onTouchMove={handleTouchMove}
                    onTouchStart={handletouchStart}
                    onTouchEnd={handletouchEnd}
                    ref={imgRef}
                    style={{
                        transform: `translateX(calc(0% - ${transformX}px))`,
                        // outline: (Math.abs(transformX)) > Math.abs(imageWidth / 2) ? '2px solid red' : 'none',
                        // display :isNextLoaded && isPrevLoaded && !isLoading ? 'block' : 'none',
                        // opacity: snapToMiddle ? 0 : 1

                    }}
                /> : <ImagePlaceholder blurhash={galleryObj?.images[galleryObj.currentImgIdx].blurhash} />}

                {/* <img class={style.next_image} src={galleryObj?.images[nextImgIndex]?.original}
                    onLoad={() => setIsNextLoaded(true)}
                    style={{
                        opacity: transformX > 0 ? ((Math.abs(transformX)) / Math.abs(imageWidth)) : 0,


                        // opacity: galleryObj.currentImgIdx + 1 < galleryObj?.images?.length ? '1' : '0',
                        transform: `translateX(calc(100% - ${transformX}px))`,
                        // transition: snapToMiddle ? transition : '',
                        // display :isNextLoaded && isPrevLoaded && !isLoading ? 'block' : 'none',
                    }}
                /> */}
            </picture>

            {/* </section> */}
            {isLoading && <LoadSpinner />}

            {
                !isLoading || isLoading ? null
                    : (
                        <section class={style.overlay_footer}>
                            {chunkedImages?.[currentChunkIdx]?.map((imageObj: any) => {
                                const imgPath = imageObj?.thumbnail?.replace('650', '250');

                                return (
                                    <picture>
                                        {isWebPSupported ? <source srcset={imgPath + '&fm=webp'} type="image/webp" /> : null}

                                        <img style={getCurrentImageStyle(imageObj.idx)} onClick={(e) => onImgClick(e, imageObj.idx)} src={imgPath} />
                                    </picture>
                                )
                            })}
                        </section>
                    )
            }
            {/* {!isLoading && <label>{currentImgIdx + 1} / {galleryObj?.images?.length}</label>} */}
            <label>{currentImgIdx + 1} / {galleryObj?.images?.length}</label>
            {/* <label>{testDeltaX}</label> */}
        </div>
    )
}