import { h } from 'preact';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'preact/hooks';
import { usePrerenderData } from '@preact/prerender-data-provider';
import { IImage } from 'src/components/gallery/gallery/gallery';
import { IPriceList } from 'src/interfaces';

interface IHomeData {
    nailsImages: IImage[] | undefined,
    nailsImagesHome: IImage[] | undefined,
    cosmeticsImages: IImage[] | undefined,
    cosmeticsImagesHome: IImage[] | undefined,
    priceList: IPriceList[] | undefined,
    isLoading: boolean,
}

export function useLoadHomeData(props: any): IHomeData {
    const [data, isLoading, err] = usePrerenderData({ ...props, url: '/' });
    const [nailsImages, setNailsImages] = useState<IImage[] | undefined>(undefined);
    const [nailsImagesHome, setNailsImagesHome] = useState<IImage[] | undefined>(undefined);
    const [cosmeticsImages, setCosmeticsImages] = useState<IImage[] | undefined>(undefined);
    const [cosmeticsImagesHome, setCosmeticsImagesHome] = useState<IImage[] | undefined>(undefined);
    const [priceList, setPriceList] = useState<IPriceList[] | undefined>(undefined);


    useEffect(() => {
        function setPageImages(index: number, setAllImages: any, setHomeImages: any, href: string | null = null) {
            const allImg = data?.data?.media?.[index]?.pictures?.map((curData: any) => ({
                thumbnail: curData?.thumbnailSrc,
                // original: 'https://vass-art-and-beauty.imgix.net' +  curData?.imgOriginal.replace('/assets/uploads/', '/'),
                original: curData?.src,
                label: curData?.alt,
                blurhash: curData?.blurhash,
                isVisibleOnHomePage: curData?.isVisibleOnHomePage
            }))?.filter((curData: any) => !isNil(curData?.blurhash) || curData?.isVisibleOnHomePage);


            const homeImages = allImg?.filter((image: any) => !!image?.isVisibleOnHomePage);

            if (!isEmpty(homeImages) && href) {
                homeImages[homeImages?.length - 1].href = href;
            }

            setAllImages(allImg?.map((img: any) => ({
                original: img?.original,
                thumbnail: img?.thumbnail,
                blurhash: img?.blurhash,
                label: img?.alt,
            })));

            setHomeImages(homeImages);
        }


        function updateNails() {
            setPageImages(1, setNailsImages, setNailsImagesHome, '/kormok');
        }

        function updateCosmetics() {
            setPageImages(0, setCosmeticsImages, setCosmeticsImagesHome)
        }

        function updatePrices() {
            const prices = data?.data?.prices;

            setPriceList(prices);
        }

        function updateData() {
            updateNails();
            updatePrices();
            updateCosmetics();
        }

        if (data && !isLoading) {
            updateData();
        }
    }, [data, isLoading]);

    return {
        nailsImages,
        nailsImagesHome,
        cosmeticsImages,
        cosmeticsImagesHome,
        priceList,
        isLoading
    }
}