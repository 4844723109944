import { h, Component } from 'preact';
import { Route, Router } from 'preact-router';
import { Provider } from '@preact/prerender-data-provider';
import Home from '../routes/home';
import Nails from '../routes/nails';
import { useContext } from 'preact/hooks';
import NotFoundPage from '../routes/notfound';
import MainContextProvider from './context/MainContext';
import Footer from './footer/footer';
import ImageOverlay from './gallery/overlay';
import Loading from './loading/loading';
import Navigation from './nav/nav';
import DataWrapper, { dataContext } from './dataWrapper/DataWrapper';

export default class App extends Component {
    [x: string]: any;

    handleRoute = (e: any) => {
        const { setCurrentUrl } = useContext(dataContext);

        setCurrentUrl(e.url);

        this.currentUrl = e.url;

        // setTimeout(() => {
        //     if (typeof window !== 'undefined') {
        //         window.scrollTo(0,0)
        //     }
        // }, 200);
    };

    render(props: any) {
        return (
            <Provider value={props}>
                <div id="preact_root">
                    <head>
                        <title>Vass Art and Beauty</title>
                    </head>
                    <MainContextProvider>
                        <section>
    
                            <DataWrapper>
                                <Router onChange={this.handleRoute}>
                                    <Home path="/" />
                                    <Nails path="/kormok" />
                                    <Nails path="/nails" />
                                    {/* <Route path="/" component={Home} /> */}
                                    {/* <Route path="/nails" component={Nails} /> */}
                                    <NotFoundPage default />
                                </Router>

                                <Navigation />
                                <Footer />
                            </DataWrapper>
                        </section>
                        {/* <Header /> */}
                        <ImageOverlay />
                    </MainContextProvider>
                </div>
            </Provider>
        );
    }
}