import { h, RefObject } from 'preact';
import { useEffect, useState, useLayoutEffect } from 'preact/hooks';

export function preloadImage(src: string, isWebPSupported: boolean) {
  const imgSrc = !isWebPSupported ? src : `${src}&fm=webp`;

  return new Promise((resolve, reject) => {
    // resolve(true);
    const img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = img.onabort = function () {
      reject(imgSrc)
    }
    img.src = imgSrc;
  });
}

export function useImagePreloader(imageList: string[] | undefined, isWebPSupported: boolean) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)

  useEffect(() => {
    let isCancelled = false

    async function effect() {

      if (isCancelled || !imageList) {
        return
      }

      const imagesPromiseList: Promise<any>[] = []
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i, isWebPSupported))
      }

      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [imageList])

  return imagesPreloaded;
}