import { isEmpty } from "lodash";
import { createContext, h } from "preact";
import { useEffect, useState, useRef } from "preact/hooks";
import { preloadImage } from "../dataWrapper/useOnLoadImages";
import { IImage } from "../gallery/gallery/gallery";

interface IContext {
    isMobile: boolean;
    winWidth: number;
};

interface IProps {
    children: JSX.Element | JSX.Element[]
}

interface IGallery {
    url?: string;
    label?: string;
    images?: IImage[];
    isOpen: boolean;
    currentImgIdx?: number;
}

export const mainContext = createContext<IContext | null>(null);

export default function MainContextProvider({ children }: IProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [browserZoomLevel, setBrowserZoomLevel] = useState(1);
    const [navHeight, setNavHeight] = useState<number>(0);
    const [currentPath, setCurrentPath] = useState<string | undefined | null>(null);
    const [winWidth, setWinWidth] = useState<number>(0);
    const [preLoadedImages, setPreLoadedImages] = useState<IImage>();
    const [galleryObj, setGalleryObj] = useState<IGallery>({
        isOpen: false,
    });

    const [isWindowLoaded, setIsWindowLoaded] = useState(false);

    const [isWebPSupported, setIsWebPSupported] = useState(() => {
        if (typeof document === 'undefined') {
            return false;
        }

        const elem = document.createElement('canvas');
        let isSupported = false;

        if (!!(elem.getContext && elem.getContext('2d'))) {
            isSupported = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
        }

        return isSupported;
    });

    function handleWindowLoaded(e: Event) {
        setIsWindowLoaded(true);
    }

    useEffect(() => {
        async function preloadAsync() {
            if (!preLoadedImages) return;

            // const [isLoadedOriginal, isLoadedThumbnal] = await Promise.all([preLoadedImages.original, preLoadedImages.thumbnail].map(imgSrc => preloadImage(imgSrc, isWebPSupported)));
            // preLoadedImages.loaded = true;

            setPreLoadedImages(undefined);
        }

        if (preLoadedImages) {
            preloadAsync();
        }
    }, [preLoadedImages]);

    useEffect(() => {
        setIsMobile(window.innerWidth < 960);
        setBrowserZoomLevel(Math.round(document.documentElement.clientWidth / window.innerWidth));


        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 960);
            setWinWidth(window.innerWidth);
            setBrowserZoomLevel(Math.round(document.documentElement.clientWidth / window.innerWidth));
        });
    });

    const data = {
        isMobile,
        winWidth,
        currentPath,
        galleryObj,
        setGalleryObj,
        navHeight,
        setNavHeight,
        isWindowLoaded,
        isWebPSupported,
        preLoadedImages,
        setPreLoadedImages,
    }


    return (
        <div ref={ref}>
            <mainContext.Provider value={data}>
                {children}
            </mainContext.Provider>
        </div>
    )
}