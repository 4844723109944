import { isFunction } from "lodash";
import { h } from "preact";
import { route, getCurrentUrl } from 'preact-router';
import { Link } from "preact-router/match";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { mainContext } from "../context/MainContext";
import { dataContext } from "../dataWrapper/DataWrapper";
import style from './style.css';

interface INavItem {
    href: string,
    label: string
}

const navList: INavItem[] = [
    { href: '#home', label: 'Kezdőlap' },
    { href: '#kormok', label: 'Körmök' },
    { href: '#kozmetika', label: 'Kozmetika' },
    { href: '#arlista', label: 'Árlista' },
    { href: '#elerhetosegek', label: 'Elérhetőségek' },
    // {href: '/login', label: 'Belépés'},
]

function NavItems(props: any): any {
    const { onClick, setScrollAfterRouteElem, currentUrl } = props;

    const prefix = currentUrl !== '/' ? '/' : '';

    function handleOnClick(e: Event, navItem: any) {
        if (isFunction(onClick)) {
            onClick();
        }

        if (getCurrentUrl() !== '/nails') {
            return;
        }

        // if (isFunction(setScrollAfterRouteElem))
        //     setScrollAfterRouteElem(navItem?.href);
    }

    // const prefix = '';

    return navList.map(navItem => (
        <li class={style.nav_item}>
            <a data-native onClick={onClick} href={prefix + navItem.href}>{navItem.label}</a>
        </li>
    ));
}

function MobileNavigation(props: any) {
    const { onClick, navSize, isOpen } = props;

    const transformPercentage = isOpen ? 0 + navSize : -100;
    const transformOpen = `${navSize}px`;
    const transformClosed = `-100%`;

    return (
        <ul class={style.mobileNavWrapper} style={{
            transform: `translateY(${isOpen ? transformOpen : transformClosed})`,
            opacity: isOpen ? 1 : 0,
            // maxHeight: isOpen ? '100%' : '0%'
        }}>
            <NavItems onClick={onClick} />
        </ul>
    )
}

export default function Navigation() {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const navRef = useRef<HTMLElement | null>(null);

    const [transitions, setTransitions] = useState({
        opacity: 0,
        translateY: -100
    });

    const { galleryObj, winWidth, isMobile, setNavHeight, navHeight }: any = useContext(mainContext);
    const { currentUrl }: any = useContext(dataContext);

    function redirectHome() {
        if (window) {
            route('/');
            window.scrollTo(0, 0)
        }
    }

    useEffect(() => {
        if (!isMobile) {
            setMobileNavOpen(false);
        }
    }, [isMobile]);

    useEffect(() => {
        if (navRef.current) {
            setNavHeight(navRef.current.clientHeight);
            document.body.style.paddingTop = `${navRef.current.scrollHeight}px`;
            document.documentElement.style.scrollPaddingTop = `${navRef.current.clientHeight * .7}px`;
            setTimeout(() => {
                setTransitions({
                    opacity: 1,
                    translateY: 0,
                })
            }, 500);
        }
    }, [navRef.current, winWidth]);


    if (galleryObj.isOpen) {
        return null;
    }

    return (
        <nav class={style.nav_container} ref={navRef} style={{
            opacity: transitions.opacity,
            transform: `translateY(${transitions.translateY}%)`
        }}>

            <section class={style.nav_info_bar}>
                <div class={style.info_div}><p>+36 20 587 5115</p></div>
                <div class={style.info_div}><p>info@vassvirag.hu</p></div>
                <div class={style.info_div}><p>@virag_vass</p></div>

            </section>
            <div class={style.nav_list}>
                <section class={style.logo} onClick={() => redirectHome()}>
                    <h2>Vass Virág</h2>
                </section>

                <div class={style.hamburger} onClick={() => setMobileNavOpen(p => !p)}>
                    <div class={style.hamburger_active} style={isMobileNavOpen ? {
                        opacity: '1',
                        scale: '1.3',
                    } : { opacity: '0', scale: '1' }} />
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 16 16" width="16" height="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="m2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5" />
                    </svg>
                </div>

            <ul class={style.nav_items}>
                    <NavItems currentUrl={currentUrl} />
                </ul>

            </div>


            <MobileNavigation onClick={() => setMobileNavOpen(false)} isOpen={isMobileNavOpen} navSize={navHeight} />
            {/* {
                isMobileNavOpen ? <MobileNavigation onClick={() => setMobileNavOpen(false)} /> : null
            } */}

        </nav>
    )
}