import { flattenDepth } from 'lodash';
import { h, createContext } from 'preact';
import { getCurrentUrl } from 'preact-router';
import { useRef, useMemo, useEffect, useState, useLayoutEffect, useContext } from 'preact/hooks';
import { mainContext } from '../context/MainContext';
import { IImage } from '../gallery/gallery/gallery';
import Loading from '../loading/loading';
import { useLoadHomeData } from './useLoadHomeData';
import { useImagePreloader } from './useOnLoadImages';

interface IProps {
    children: JSX.Element | JSX.Element[]
}

export const dataContext = createContext<any | null>(null);

export default function DataWrapper(props: IProps) {
    const [currentUrl, setCurrentUrl] = useState<string | null>();

    const { isWebPSupported }: any = useContext(mainContext);

    const wrapperRef = useRef<HTMLDivElement>(null);
    // const [isImagesLoaded, setIsImagesLoaded] = useState(false);

    const data = useLoadHomeData(props);

    const homeNailsImagesPreload = data?.nailsImagesHome?.map(img => img.thumbnail) || [];
    const homeCosmeticsImagesPreload = data?.cosmeticsImagesHome?.map(img => img.thumbnail) || [];

    // const isImagesLoaded = useImagePreloader(flattenDepth([...homeCosmeticsImagesPreload, ...homeNailsImagesPreload]), isWebPSupported);

    // useEffect(() => {
    //     if (!isImagesLoaded) return;
        
    //     const allImg: IImage[] = [...(data?.nailsImagesHome || []), ...(data?.cosmeticsImagesHome || [])];
    //     allImg?.forEach(img => {
    //         // img.loaded = true;
    //     });
    // }, [isImagesLoaded]);

    // const isImagesLoaded = useOnLoadImages(wrapperRef, loadedImages);

    useEffect(() => {
        function waitForElm(selector: string): Promise<any> {
            return new Promise(resolve => {
                if (document.querySelector(selector)) {
                    return resolve(document.querySelector(selector));
                }

                const observer = new MutationObserver(mutations => {
                    if (document.querySelector(selector)) {
                        resolve(document.querySelector(selector));
                        observer.disconnect();
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true
                });
            });
        }

        if (!currentUrl) { return };

        const currentHash = window.location.hash;

        if (currentHash) {
            waitForElm(currentHash).then(element => {
                setTimeout(() => {
                    element?.scrollIntoView()
                }, 400);
            });
        }
    }, [currentUrl]);

    const exports = {
        currentUrl,
        setCurrentUrl,
    }

    return (
        <div ref={wrapperRef}>
            <dataContext.Provider value={{ ...data, ...exports }}>
                {props.children}
            </dataContext.Provider>

            <Loading isResourcesLoaded={!data.isLoading} />
        </div>
    )
}